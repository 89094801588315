<template>
  <div>
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header class="text-h6 text-uppercase font-weight-regular py-2 panelheader">Binari lavorazione crane</v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-container fluid class="py-0 my-0">
            <v-row>
              <v-col align-self="center">
                <span class="px-0 mx-2 text-h5 font-weight-regular">
                  <v-icon color="primary" x-large>mdi-crane</v-icon>
                </span>
                <v-chip 
                  v-for="vehicle in vehicles" 
                  :key="vehicle.id" 
                  @click="onClickSelectedVehicle(vehicle)" 
                  :color="vehicleChipColor(vehicle)"
                  class="pa-3 ma-3 elevation-1"
                  large>
                    <span :class="`text-h6 font-weight-regular ${vehicleChipTextColor(vehicle)}`">{{vehicle.codice}} - {{vehicle.descrizione}}</span>
                </v-chip>
              </v-col>
            </v-row>

            <v-divider/>
         
            <v-row v-for="railCode in railsPark" :key="railCode">
              <v-col>
                <span class="px-0 mx-2 text-h5 font-weight-regular">
                  {{railCode.toUpperCase()}}
                </span>
                <v-chip 
                  v-for="rail in railsByVehicle[railCode]" 
                  :key="rail.id" 
                  @click="onClickSelectedRail(rail)" 
                  :color="railChipColor(rail)"
                  class="pa-2 mx-5 elevation-1"
                  large>
                    <span :class="`px-2 mx-2 text-h5 font-weight-regular ${railChipTextColor(rail)}`">{{rail.binario.numero}}</span>
                </v-chip>
              </v-col>
            </v-row>
          </v-container>       
        </v-expansion-panel-content>

      </v-expansion-panel>
    </v-expansion-panels>

    <v-card>
      <v-card-title class="text-h6 text-uppercase font-weight-regular py-2 my-2 panelheader">
        Viaggi treno senza lavorazione
      </v-card-title>
      <v-card-text>
        <v-simple-table class="py-1 ma-1">
          <template v-slot:default>
            <thead>
              <tr>
                  <th class="text-body-1 text-left text-uppercase font-weight-regular header">&nbsp;</th>
                  <th class="text-body-1 text-left text-uppercase font-weight-regular header">Codice viaggio</th>
                  <th class="text-body-1 text-left text-uppercase font-weight-regular header">Nome viaggio</th>
                  <th class="text-body-1 text-left text-uppercase font-weight-regular header">Provenienza</th>
                  <th class="text-body-1 text-left text-uppercase font-weight-regular header">Eta</th>
                  <th class="text-body-1 text-left text-uppercase font-weight-regular header">Parco</th>
                  <th class="text-body-1 text-left text-uppercase font-weight-regular header">Binario</th>
                  <th class="text-body-1 text-left text-uppercase font-weight-regular header">Seleziona</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="viaggio in viaggiArrivoSenzaLavorazioni" :key="viaggio.convoglioId">
                  <td class="text-body-1 text-left font-weight-regular">&nbsp;</td>
                  <td class="text-body-1 text-left font-weight-regular">{{ viaggio.viaggioTrenoCodice}}</td>
                  <td class="text-body-1 text-left font-weight-regular">{{ viaggio.viaggioTrenoNome}}</td>
                  <td class="text-body-1 text-left font-weight-regular">{{ viaggio.viaggioTrenoProvenienzaLabel}}</td>
                  <td class="text-body-1 text-left font-weight-regular">{{ viaggio.viaggioTrenoEta}}</td>
                  <td class="text-body-1 text-left font-weight-regular">{{ viaggio.binarioParcoBinariCodice}}</td>
                  <td class="text-body-1 text-left font-weight-regular">{{ viaggio.binarioNumero}}</td>
                  <td class="text-body-1 text-center font-weight-regular">                       
                    <v-checkbox x-large v-model="convogliSelezionati" :value="viaggio" @change="validaConvogliSelezionati" />                     
                  </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-divider/>
        <div class="text-h5 font-weight-regular text-right my-3">
          <b>{{convogliSelezionati.length}}</b> viaggi selezionati              
        </div>             
      </v-card-text>
    </v-card>

    <v-container fluid>
      <v-row>                       
        <v-col cols="6" class="text-center">
          <v-btn block x-large color="error" class="font-weight-regular text-h5" @click="annullaCreaNuovaLavorazione" title="Annulla creazione nuova lavorazione">
            Annulla
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn v-if="convogliSelezionatiStessoParco" block x-large color="primary" class="font-weight-regular text-h5" @click="doCreaNuovaLavorazione" :disabled="convogliSelezionati.length==0" title="Crea nuova lavorazione con i viaggi selezionati">
            Pianifica lavorazione viaggi
          </v-btn>
          <v-btn v-else block x-large color="warning" class="font-weight-regular text-h5">  
            <v-icon left large class="ma-5">
              mdi-alert-outline
            </v-icon>            
              I viaggi selezionati non hanno stesso parco binari &nbsp;
            <v-icon right large class="ma-5">
              mdi-alert-outline
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    </div>
</template>

<script>
import ToastMixin from '../../mixins/ToastMixin';
import FormatsMixins from "../../mixins/FormatsMixins";

export default {
  name: "CreaLavorazione",
  data() {
    return { 
        panel: [0],
        vehicles: [],
        selectedVehicle: undefined,
        selectedRail: undefined,
        convogliSelezionati: [],
        convogliSelezionatiStessoParco: true,
        viaggiArrivo: [],
        viaggiArrivoSenzaLavorazioni: [],
      }
  },
  mixins: [ToastMixin, FormatsMixins],
  mounted() {
    this.retrieveData();
  },
  computed: {
    railsByVehicle() {
      var railsByVehicle = {};
      if (this.selectedVehicle && this.selectedVehicle.binariMezzo && this.selectedVehicle.binariMezzo.length>0) {
        railsByVehicle = _.mapValues(_.groupBy(this.selectedVehicle.binariMezzo, 'binario.parcoBinari.codice'),
                          clist => clist.map(binarioMezzo => _.omit(binarioMezzo, 'binario.parcoBinari.codice')));
        // console.log("railsByVehicle", railsByVehicle);        
      }
      return railsByVehicle;
    },
    railsPark() {
      return Object.keys(this.railsByVehicle);
    }
  },
  methods: {
    async retrieveData() {
      this.vehicles = await this.$api.get(this.$apiConfiguration.BASE_PATH + "mezziMovimentazione/findAll");  
      // console.log("vehicles", this.vehicles);
    },
    async doCreaNuovaLavorazione(){
      let convogliIds = [];
      this.convogliSelezionati.forEach(convoglio => {
        convogliIds.push(convoglio.convoglioId);
      });
      const lavorazioneId=await this.$api.trainData.newLavorazioneFromConvogliId(convogliIds);
      if(lavorazioneId!=null) {
        this.$router.push({ path: `/lavorazioni/${lavorazioneId}` });
      }
    },
    annullaCreaNuovaLavorazione() {
      this.$router.push({path:"/lavorazioni"});
    },
    validaConvogliSelezionati() {
      if (this.convogliSelezionati!==undefined && this.convogliSelezionati.length>0) {
        this.convogliSelezionatiStessoParco = true;
        let parco = this.convogliSelezionati[0].binarioParcoBinariCodice;
        this.convogliSelezionati.forEach(convoglio => {
          if (parco!==convoglio.binarioParcoBinariCodice) {
            this.convogliSelezionatiStessoParco = false;
          }
        });      
      }      
    },
    onClickSelectedVehicle(vehicle) {
      this.selectedVehicle = vehicle;
      this.selectedRail = {};
      this.viaggiArrivoSenzaLavorazioni = [];
    },
    vehicleChipColor(vehicle) {
      if (vehicle!=null && this.selectedVehicle!=null && vehicle.id === this.selectedVehicle.id) {
        return "primary";
      }
      return "grey lighten-3";     
    },
    vehicleChipTextColor(vehicle) {
      if (vehicle!=null && this.selectedVehicle!=null && vehicle.id === this.selectedVehicle.id) {
        return "white--text";
      }
      return "";     
    },
    async onClickSelectedRail(rail) {
      this.selectedRail = rail;
      // console.log("onClickSelectedRail | selectedRail", this.selectedRail);
      this.viaggiArrivo = await this.$api.trainData.getViaggiArrivoLavorazioneCrane();
      this.viaggiArrivoSenzaLavorazioni = this.viaggiArrivo.filter( v => v.lavorazioneId==null && v.binarioId===this.selectedRail.binarioId);
      this.convogliSelezionati = [];
      this.convogliSelezionatiStessoParco = true;
      // console.log("onClickSelectedRail | viaggiArrivoSenzaLavorazioni:", this.viaggiArrivoSenzaLavorazioni);
    },
    railChipColor(rail) {
      if (rail!=null && this.selectedRail!=null && rail.id === this.selectedRail.id) {
        return "primary";
      }
      return "grey lighten-3";     
    },
    railChipTextColor(rail) {
      if (rail!=null && this.selectedRail!=null && rail.id === this.selectedRail.id) {
        return "white--text";
      }
      return "";     
    },
   }
}
</script>

<style scoped>

</style>
